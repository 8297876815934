export const HOME = "/";
export const PRODUCTS = "/products";
export const ORDERS = "/orders";
export const SUPPLIERS = "/suppliers";
export const SALES = "/sales";
export const LOANS = "/loans";
export const CUSTOMERS = "/customers";
export const HISTORY = "/history";
export const SIGN_IN = "/signin";
export const ADMIN = "/admin";
export const PROFILE = "/profile";
export const FORGOT_PASSWORD = "/forgot";
