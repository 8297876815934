export const firebaseAuthConfig = {
  apiKey: "AIzaSyBhSK14j2-N5HJHe62UqavFDJ9fwDNJA6s",
  authDomain: "simple-inventory-manager.firebaseapp.com",
  databaseURL: "https://simple-inventory-manager.firebaseio.com",
  projectId: "simple-inventory-manager",
  storageBucket: "simple-inventory-manager.appspot.com",
  messagingSenderId: "702460828824",
  appId: "1:702460828824:web:3b26775c216f1089"
};

export const demoConfig = {
  apiKey: "AIzaSyCing7P5EuYw_EQW3ynf1X2OHGKA9hyeb8",
  authDomain: "inventory-management-test.firebaseapp.com",
  databaseURL: "https://inventory-management-test.firebaseio.com",
  projectId: "inventory-management-test",
  storageBucket: "",
  messagingSenderId: "1003896851238",
  appId: "1:1003896851238:web:e0abaae182b23ac5870fe7"
}

export const data = ""